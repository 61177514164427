import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { AuthGuard } from './_helpers/auth.guard';
import { ProfileComponent } from './profile/profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { TermsComponent } from './terms/terms.component';
import { LicenseComponent } from './license/license.component';
import { MemberComponent } from './member/member.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoginComponent } from './login/login.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogDetailsComponent } from './blogs/blog-details/blog-details.component';
import { IconsComponent } from './icons/icons.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FigmaComponent } from './figma/figma.component';
import { SketchComponent } from './sketch/sketch.component';
import { ThreeDimensionComponent } from './three-dimension/three-dimension.component';
import { FaqsComponent } from './faqs/faqs.component';
import { IllusComponent } from './illus/illus.component';
import { PaymentComponent } from './payment/payment.component';
import { SalesComponent } from './sales/sales.component';
import { MarketingComponent } from './marketing/marketing.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PricingComponent } from './pricing/pricing.component';
import { IllustratorComponent } from './illustrator/illustrator.component';
import { HometwoComponent } from './hometwo/hometwo.component';
import { CagesComponent } from './cages/cages.component';
import { CagesiconComponent } from './cagesicon/cagesicon.component';
import { PluginComponent } from './plugin/plugin.component';
import { UploadDetailsComponent } from './upload-details/upload-details.component';
import { KaleidoscopeComponent } from './kaleidoscope/kaleidoscope.component';
import { IsoAllComponent } from './iso-all/iso-all.component';
import { TranslateAiComponent } from './translate-ai/translate-ai.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'marketplace', component: HometwoComponent },
  { path: 'search', component: SearchComponent, },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'profile-edit', component: EditProfileComponent, canActivate: [AuthGuard] },
  { path: 'privacy-terms', component: TermsComponent },
  { path: 'license', component: LicenseComponent },
  { path: 'member', component: MemberComponent },
  { path: 'contact', component: ContactUsComponent },
  // { path: 'login', component: LoginComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'blog/:blogId', component: BlogDetailsComponent },
  { path: 'icons-search', component: IconsComponent },
  { path: 'icons', component: CagesComponent },
  { path: 'icon', component: CagesiconComponent },
  { path: 'icon/:category', component: CagesiconComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'figma', component: FigmaComponent },
  { path: 'sketch', component: SketchComponent },
  { path: 'kaleidoscope', component: KaleidoscopeComponent },
  { path: 'iso-all', component: IsoAllComponent },
  { path: 'translate-ai', component: TranslateAiComponent },
  { path: '3d-illustrations', component: ThreeDimensionComponent },
  { path: 'faq', component: FaqsComponent },
  { path: 'illustration', component: IllusComponent },
  { path: 'illustration/:category', component: IllusComponent, },
  { path: 'payment', component: PaymentComponent },
  // { path: 'sales', component: SalesComponent },
  // { path: 'mkt', component: MarketingComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'illustrator', component: IllustratorComponent },
  { path: 'smart-plugins', component: PluginComponent },
  { path: 'upload-details', component: UploadDetailsComponent },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes , { useHash: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
