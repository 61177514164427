import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import AOS from 'aos';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  title = 'About Us | Crafttor';

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Crafttor is always looking for new concepts of designing high end illustrations which ultimately results in a glory for our users.'},
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
    AOS.init();
  }

  openWorkspace() {
    window.open("https://workspace.crafttor.com/home", "_blank");
  }

  items = [
    { image: 'assets/img/sandeep.jpeg', name: 'Sandy', designation: 'Design Head', exp: 'Experience: More than 13 years', cert: 'Illustrator, Figma, Sketch, etc' },
    { image: 'assets/img/aki.jpeg', name: 'Akie', designation: 'Product Owner', exp: 'Experience: More than 9 years', cert: 'AWS, Mobile apps, Angular, etc' },
    { image: 'assets/img/josh.jpeg', name: 'Josh', designation: 'Techie', exp: 'Experience: More than 9 years', cert: 'Python(Django), AWS, Database, etc' },
    { image: 'assets/img/kapil.jpeg', name: 'Kapil', designation: 'Lead Techie', exp: 'Experience: More than 13 years', cert: 'Python, PostgreSQL, Nodejs etc' },
    { image: 'assets/img/inder.jpeg', name: 'Indi', designation: 'Techie', exp: 'Experience: More than 13 years', cert: 'Plugins(Figma, Sketch), Javascript, etc' },
    { image: 'assets/img/amri.jpeg', name: 'Amri', designation: 'HR Manager', exp: 'Experience: More than 12 years', cert: 'Admin, Hiring, etc' },
  ];

  currentIndex = 0;
  visibleItems = 2.5; // Number of visible items (2.5)

  // Go to the previous slide
  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  // Go to the next slide
  nextSlide() {
    if (this.currentIndex < this.items.length - this.visibleItems) {
      this.currentIndex++;
    }
  }
}
