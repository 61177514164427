<section class="faq-section-top">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="inner-content">
          <h2>FAQs</h2>
          <hr>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-section design-faq-main">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is Crafttor?</h2>
          <p>Crafttor is a digital platform with full of high quality and latest topics illustrations. Crafttor's sole purpose is to give digital craftsmen a better tool to create awesome products. For now crafttor provides a beautiful website with two amazing plugins (Figma & Sketch) for creative designers.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Why is the name Crafttor?</h2>
          <p>The name Crafttor comes out of a word craft which means designing decorative things with hands and crafttor defines that we create amazing illustrations with hands especially as the best thing we can do for our creative thinkers/designers. At crafttor we use our skills for your product stories.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is Sketch Plugin?</h2>
          <p>The sketch is a Mac-only, vector-based digital design tool that is primarily useful for UI/UX design of web, desktop, mobile, and wearables. Plugins improve functionality and workflow by providing creative elements that teams can directly integrate into their designs. You can add features created by developers across the globe.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is Figma Plugin?</h2>
          <p>Figma Plugin is a web-based prototyping tool and a vector graphics editor for teams to collaborate and bring ideas to life. You can integrate designs, illustrations, and other web elements offered by other brands to create your websites, UI/UX design, and a lot more.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Which membership is more beneficial?</h2>
          <p>Benefits of every membership is based on customer's requirements. If anyone wants to download less PNG & SVG with 3D illustrations then Individual is the plan and if requirement of download is more then Premium plan is a good option with one custom illustration by us.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is custom illustration in Premium plan?</h2>
          <p>For our premium members this is a special add-on in the plan. For premium members we will design a custom illustration as per their requirements. A custom illustration can be based on any topic with different sizes as required. We will provide the custom illustration in PNG & SVG format only.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Who should use Sketch Plugins?</h2>
          <p>Individuals or teams dedicated to designing icons, UI/UX, prototypes, user flow, UI libraries, vector illustrations, social media images, and presentations. However, you must have a MAC to use Sketch.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I access Crafftor on Figma?</h2>
          <p>To access our illustrations on Figma, create a free account and choose the starter pack with 30-days version history and unlimited cloud storage. Go to Menu > Plugins > Browse Plugins in Community and search for Crafftor. Install the plugin and start using our illustrations for your projects.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Is there any offer or a free trial?</h2>
          <p>Yes, we do have a free plan & offers going on. In fact, we are a step ahead. You may use our graphic assets free of cost for personal or commercial purposes but there is a limit in downloading the assets. Also we do have an offer going on yearly plans for both Individual and Premimun.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Which plan should I choose?</h2>
          <p>We have one free and two paid plans going on. Free plan has a per day download limit and paid plans like individual & premium also have download limits but there is no daily download limit. You can check out https://crafttor.com/member for more details about all the plans.</p>
            <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How to use Sketch plugins?</h2>
          <p>Find your desired plugin on the Sketch website and click on the installation button. You will be redirected to a GitHub repository. Click on the ‘clone or download button’ and click on ‘download zip’ from the drop-down menu. Double-click on the Sketch plugin, and it’s installed. </p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Is there any icons download limit?</h2>
          <p>No, Please note that free assets like icons do not come with any download limitations. Anyone can download any number of icons in a day. There might be some download limits to our icons in the future and we will update our license and other data to let people know about any change happen.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Who should use Figma Plugins?</h2>
          <p>Figma is a collaborative tool with a free plan for anyone working in the digital space. Although it’s suitable for both individual projects and team endeavors, the browser-based application saves your work in real-time. Other prototyping tools either don’t have the same ability or entirely lack this essential feature.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Do you offer different categories for icons?</h2>
          <p>Yes, we offer open-source icons within different categories, and you can also search which icon is required via Search icons option. Right now we are offering 3 different categories to our beautiful icons. We do provide high-quality SVG icons based on the latest topics like airpods, browsers, etc.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Are the icons free?</h2>
          <p>Yes, We have beautiful and clean pixel SVG icons for free. Anyone can download these free icons and use anywhere they want like mobile apps, websites, etc. We are providing SVG icons for websites, mobile apps, and for your other products too.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How to use Figma plugins?</h2>
          <p>Figma is available as a cloud-based, in-browser plugin and is universally available across all platforms. Register for free to start your creative journey. You can install plugins for illustrations, icons, mobile designs, web designs, and a lot more. </p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Do you have PNG format icons?</h2>
          <p>No, we stick to SVG format icons. As per today's trend, people use SVG format icons for their platforms like websites or mobile apps because SVG format does not pixelate either if you increase/decrease the size of an icon. The other use case is you can easily change the color of the SVG icon because it is an XML code base.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I access Crafftor on Sketch?</h2>
          <p>We have made the installations easy for you. Click on the Download button at <b>https://crafttor.com/sketch</b> and download will start. Once download completed you’ve to install the Crafttor plugin, and then you can access its features from the Plugins menu.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What kind of illustrations do you offer?</h2>
          <p>At Crafttor, we offer different sets of illustrations like Sandy, Akki, Kako etc.Also, we have trendy & styling 3D illustrations. In all our sets of illustrations we have defined themes like bulky characters, vibrant color characters, latest topic illustrations, etc. </p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I buy a membership?</h2>
          <p>You can buy either Individual or Premium membership based on your requirements. For buying a membership the user should go to the pricing page then click on Buy now button and the user will be redirected to the payment page. Pay the amount via Stripe payment gateway & all done.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I buy a single category illustration?</h2>
          <p>Currently we have paid membership for all illustration sets together but don't worry we are working on it & we will provide single category buying option soon. For buying a single category, we will provide a smooth & easy process like we have right now.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What if payment fails while buying a membership?</h2>
          <p>If your payment is deducted & you won't get the membership then don't worry it's on us only to follow what went wrong. We just want you to email us at info@crafttor.com for the same & we will resolve your issue asap.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What if my payment is deducted twice or more?</h2>
          <p>If the amount is deducted twice or more by the payment gateway provider then just drop us an email at info@crafttor.com & we will look into the matter asap & will refund the extra amount deducted as per payment gateway's policies.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I download large 3D illustrations?</h2>
          <p>For downloading Large 3D illustrations a user needs to buy a membership & only after buying a membership a user is allowed to download Large 3D illustrations(no download limit). Without membership we only provide small 3D illustrations with download limit.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I get 3D custom illustrations?</h2>
          <p>We do offer custom illustration with our premium plan but not with individual. We include one single custom illustration in premium package, it can be either 2D or 3D but only one with one premium membership.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I get more than one custom illustraion?</h2>
          <p>In case if anyone required more than one custom illustration then firstly one needs to buy premium membership. After buying premium membership one should email us for their custom illustrations requirement & if it more than one then little extra charges should be there.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What do you mean by trendy illustrations?</h2>
          <p>With the word trendy we means whatever trend is in the market our illustrations follows that like we offer vibrant color illustrations, styling theme illustrations. Also, we offer latest topic illustrations based on what is going on in the society.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What are simple illustrations?</h2>
          <p>It simply based on styling some topics in simple theme to easily understand the concept like in simple illustrations we have bitcoin, finance, digital finance, music, meeting, etc illustrations. Grab a membership & get access to all our collection of illustrations.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is Illustrator Plugin?</h2>
          <p>Illustrator Plugin is a vector graphic tool and can be use worldwide by designers of all types who wants to create illustrations, vector graphics and other use like create illustrations, graphics for web, video etc.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How to use Illustrator plugins?</h2>
          <p>Illustrator is available on Crafttor section of Adobe. Your can simply install the plugin and use it. It's a simple tool and you have to just follow whatever is showing on the plugin. Product(marketplace, workspace) you have to select and good to go. </p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I learn Illustrator plugin features?</h2>
          <p>Illustrator plugin is a great tool for designers. One can simply install the plugin and check for the options provided for different products dropdown(marketplace & workspace) and based on products plugin will show illustrations uploaded by Crafttor(marketplace) and other images uploaded by users(workspace) and use as per the need. More you learn when use the plugin.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What exactly are vector graphics?</h2>
          <p>Vector graphics are commony used by designers for creating illustrations and other images and are resolution independent. Resolution independent means when a user change the size of the image/illustration it will not effect the quality of image. The quality will remain same even if it resized number of times.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How to get refund for the plan bought?</h2>
          <p>If a user wishes not to continue using Crafttor plans or any other services in between(before time) then in that case no refund for the accounts will be initiated.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Kaleidoscope Plugin?</h2>
					<p>Kaleidoscope is a simple plugin that adds kaleidoscopic style from your own photos and materials to your projects, enhancing your design appearance. You can extract a new design pattern from a current design pattern.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How many patterns can be extracted from the Kaleidoscope Plugin?</h2>
          <p>The number of patterns that can be extracted from the Kaleidoscope Plugin depends on the input images and the parameters set by the user, such as symmetry, rotation, and color manipulation. Since the plugin uses images to generate unique patterns, the possibilities are virtually limitless, offering an endless range of pattern combinations.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What file formats does the Kaleidoscope Plugin support?</h2>
          <p>The plugin supports common image formats like JPEG, PNG, and SVG.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Can I use the Kaleidoscope Plugin with vector images?</h2>
          <p>Yes, vector images are supported, allowing for high-quality pattern outputs.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Is Crafttor Kaleidoscope Plugin compatible with both Figma and Sketch?</h2>
          <p>No, the plugin is fully compatible with Figma, making it easy to integrate into your design workflow. A Sketch version of the Kaleidoscope Plugin is in the pipeline.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Can I apply the generated patterns to multiple design projects?</h2>
          <p>Absolutely! The patterns you create can be applied across a wide range of design projects, from illustrations to user interfaces.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Translate AI Plugin?</h2>
					<p>The Translate AI Plugin uses artificial intelligence to effortlessly translate text within your designs into multiple languages.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How many outcomes are there in Translate AI Plugin?</h2>
					<p>There are 3 possible outcomes, and you can select any one or pick all three, one by one.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Which design tools are compatible with the Translate AI Plugin?</h2>
					<p>The plugin is compatible with only one design tool - Figma</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How do I use the Translate AI Plugin?</h2>
					<p>Install the plugin, select the text you want to translate, choose the target language, and let the plugin handle the translation automatically.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Iso All Plugin?</h2>
					<p>The Iso All Plugin is designed to simplify the creation of isometric designs by converting standard designs into isometric perspectives.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How do I get started with the Iso All Plugin?</h2>
					<p><a href="https://www.figma.com/community/plugin/1406167507218530657/iso-all" target="_blank"><b>Install</b></a> the plugin, select your design elements, and use the plugin’s tools to transform them into isometric views with just a few clicks.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Can I adjust the angle of the isometric projection?</h2>
					<p>Yes, the plugin allows you to customize the angle and depth of the isometric projection to fit your design needs.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>What design tools is the Iso All Plugin compatible with?</h2>
					<p>Currently, Iso All Plugin is compatible with only design tool Figma.</p>
					<hr>
				</div>
			</div>
    </div>
  </div>
</section>
