<!-- Hero Section -->
<section class="hero-section main-icons-section" style="padding:0px 0px;">
	<div class="container">
		<div class="row">
			<div class="col-sm-6">
				<div class="inner-content">
					<h2>Thousands of <br>unique <span>icons</span></h2>
					<p>that meet your requirements and give your design a unique experience.</p>
					<div class="input-group-search-icons">
						<img src="assets/img/search-grey.svg" alt="">
						<input type="text" class="form-control coupon" maxlength="60" placeholder="Search Icons" type="text" value="" [(ngModel)]="searchTerm" required>
						<button class="btn btn-primary btn-apply coupon" (click)="searchIcons()">Search</button>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="img-boxes">
					<img src="assets/img/icons-hero.svg" alt="download gif illustration">
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Cages category section -->
<section class="icon-vectorimg-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Popular Categories</h2>
			</div>
		</div>
		<div class="row" style="display: block;">
			<ul class="load-list">
				<li *ngFor="let icons of iconsCategory">
					<div class="icon-vector-box" routerLink="/icon" [queryParams]="{category: icons.id}" (click)="selectIconsValue(icons)">
						<img [src]="icons.image" alt="line icons">
					</div>

					<div class="category-title">
						<h3>{{ icons.name }}</h3>
					</div>
				</li>
			</ul>
		</div>
	</div>
</section>

<section class="icons-use-case-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-6">
				<h2>Icon use cases <br>for your <span class="design-green-txt">Design</span></h2>
			</div>

			<div class="col-sm-6 arrow-imgs">
				<img src="assets/img/arrows-icons.svg" alt="">
			</div>

			<div class="col-sm-7 main-mobile-imgs">
				<div class="first-mobile-img">
					<img src="assets/img/main-mobile-icons.svg" alt="">
				</div>

				<div class="second-mobile-img">
					<img src="assets/img/below-mobile.png" alt="">
				</div>

			</div>

			<div class="col-sm-5 mobile-watch-imgs">
				<div class="first-watch-img">
					<img src="assets/img/mobile-icons.svg" alt="">
				</div>

				<div class="second-watch-img">
					<img src="assets/img/i-watch.png" alt="">
				</div>

			</div>
		</div>
	</div>
</section>

<section class="cta-section">
	<div class="container">
		<div class="inner-imgs">
			<div class="row">
				<img class="text-hover-img" src="assets/img/multiple-file.png" alt="">
				<div class="col-md-6 ">
					<h2>Get your favourite icons across your design tools</h2>
				</div>
				<div class="col-md-6 plugin-side-img">
					<img src="assets/img/icons-plugins.svg" alt="">
				</div>
			</div>
		</div>
	</div>
</section>

<!-- <section class="show-icons-plugins">
	<div class="container">
		<div class="row">
			<div class="col-sm-3">

			</div>
			<div class="col-sm-6">
				<div class="plugin-ui-sec" (click)="openFigma()">
					<img src="assets/img/figma.svg" alt="">
				</div>

				<div class="plugin-ui-sec" (click)="openSketch()">
					<img src="assets/img/sketch.svg" alt="">
				</div>

				<div class="plugin-ui-sec" (click)="openIllustrator()">
					<img src="assets/img/aii.svg" alt="">
				</div>
			</div>
			<div class="col-sm-3">

			</div>
		</div>
	</div>
</section> -->

<section class="new-blog-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Latest Blogs</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-4" *ngFor="let blog of recentBlogs">
				<a href="#" class="inner-box">
					<div class="thumb">
						<a href="javascript:;" [routerLink]="['/blog', blog.url_slug]"><img [src]="blog.image_sqr"></a>
					</div>
					<div class="meta-data">
						<!-- <span>{{blog.date}}</span>  -->
						<span class="tag">{{blog.author}}</span>
					</div>
					<h4><a href="javascript:;" [routerLink]="['/blog', blog.url_slug]">{{blog.title}}</a></h4>
				</a>
			</div>
		</div>
	</div>
</section>

<section class="new-blog-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Community</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-4 community-email">
				<img (click)="openRequestIconsModal(requesticons)" src="assets/img/community-email.svg" alt="" style="background: #DAFDFF">
				<h4 class="community-txt">Request Icons</h4>
				<span class="community-desc-txt">Missing an icon? We’ll design it for you for free.</span>
			</div>

			<div class="col-sm-4 community-email">
				<img (click)="forum()" src="assets/img/community-msg.svg" alt="">
				<h4 class="community-txt">Forum</h4>
				<span class="community-desc-txt">Coming soon!!.</span>
			</div>

			<!-- <div class="col-sm-4 community-email" (click)="forum()">
				<img src="assets/img/community-msg.svg" alt="">
				<h4 class="community-txt">Upload your icons</h4>
				<span class="community-desc-txt">Earn money by sharing your icons. We'll upload them and give you 50% of the subscription revenue from your icons..</span>
			</div> -->

			<div class="col-sm-4 community-msg">
				<img (click)="contactUs()" src="assets/img/community-edit.svg" alt="">
				<h4 class="community-txt">Contact Us</h4>
				<span class="community-desc-txt">Connect with us for any queries.</span>
			</div>
		</div>
	</div>
</section>

<!-- FAQs Section -->
<section class="faq-section-other-pages" style="padding: 100px 0px;">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Are these icons free?</h2>
					<p>Yes, We have beautiful and clean pixel SVG icons for free. Anyone can download these free icons and use anywhere they want like mobile apps, websites, etc. We are providing SVG icons for websites, mobile apps, and for your other products too.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Is there any icons download limit?</h2>
					<p>No, Please note that free assets like icons do not come with any download limitations. Anyone can download any number of icons in a day. There might be some download limits to our icons in the future and we will update our license and other data to let people know about any change happen.</p>
					<hr>
				</div>
			</div>

			<div class="col-sm-12">
				<div class="inner-content right-question">
					<h2>Do you have PNG format icons?</h2>
					<p>No, we stick to SVG format icons. As per today's trend, people use SVG format icons for their platforms like websites or mobile apps because SVG format does not pixelate either if you increase/decrease the size of an icon. The other use case is you can easily change the color of the SVG icon because it is an XML code base.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>Do you offer different categories for icons?</h2>
					<p>Yes, we offer open-source icons across various categories, and you can also search for the icons you need using the Search Icons option. We provide a wide range of high-quality SVG icons, including those related to the latest topics like AirPods, browsers, and more.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FAQs Section END -->

<ng-template #requesticons let-requesticons>
	<div class="custom-dialog custom-dialog-centered request-icons-popup" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<img src="assets/img/cross.svg" (click)="requesticons.close()">
				</button>
				<div class="modal-body">
					<h2>Request Icons</h2>
					<div class="form-wrapper">
					<form class="row g-3" [formGroup]="requestIconsForm" (ngSubmit)="submitIconsRequest()">
						<div class="col-md-12">
							<!-- <label class="form-label">First Name<span>*</span></label> -->
							<input id="textinput" formControlName="name" type="text" placeholder="Brian Adam" class="form-control input-md" required>
							<span class="text-danger error-text"
							*ngIf="(requestIconsForm.controls.name.touched || submitted) && requestIconsForm.controls.name.errors?.required">
							Name is required
							</span>
						</div>
						<div class="col-12">
							<!-- <label for="businessEmail" class="form-label">Business Email<span>*</span></label> -->
							<input id="textinput" formControlName="email" type="email" placeholder="abc@crafttor.com" class="form-control input-md" >
							<span class="text-danger error-text"
							*ngIf="(requestIconsForm.controls.email.touched || submitted) && requestIconsForm.controls.email.errors?.required">
							Email is required
							</span>
							<span class="text-danger error-text"
							*ngIf="(requestIconsForm.controls.email.touched || submitted) && requestIconsForm.controls.email.errors?.pattern">
							Email is not valid
							</span>
						</div>
						<div class="col-12">
							<!-- <label for="company" class="form-label">Icons name<span>*</span></label> -->
							<input id="textinput" formControlName="message" type="text" placeholder="Icons name (comma separated)" class="form-control input-md" >
							<span class="text-danger error-text"
							*ngIf="(requestIconsForm.controls.message.touched || submitted) && requestIconsForm.controls.message.errors?.required">
							Icons name is required
							</span>
						</div>

						<div class="col-12">
							<button id="singlebutton" name="singlebutton button" class="btn btn-primary request-submit-btn" *ngIf="!showLeaveLoader">Submit</button>
							<button id="singlebutton" name="singlebutton button" class="btn btn-primary request-submit-btn" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>
						</div>
					</form>
				 </div>
					<p style="font-size: 13px; color: #ff0000;">{{showContactErrorMsg}}</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>
