<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Crafttor is now available on <span class="freelaner-txt">Illustrator</span></h1>
				<p>Unlock your creative potential with our plugin—effortlessly handle and manage your designs while creating stunning illustrations.</p>
				<div class="btn-wrapper">
					<a href="https://exchange.adobe.com/apps/cc/109306/crafttor-for-illustrator" target="_blank" class="button gray-btn">
						<img style="width:22px; height:24px; margin-right: 15px; " class="btnimg" src="assets/img/aii.svg" alt="" />
						Install Plugin</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<img class="sketch-main-img" src="assets/img/aii.svg" alt="">

        <h2>Illustrator Plugin</h2>
        <p>Supercharge your Illustrator experience with our dynamic plugin, crafted to seamlessly integrate and enhance your creative workflow.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-hero-section" style="background: #ffffff;">
	<div class="container">
    <div class="row before-after-section">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5 white-back-temp">
        <h5>Before Sketch</h5>
        <ul>
          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Fragmented Asset Storage.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Slow Design Iterations.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Inefficient Workflow.
          </li>
        </ul>
      </div>

      <div class="col-sm-5 white-back-temp">
        <h5>After Sketch</h5>
        <ul>
          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Unified Asset Library.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Faster Design Updates.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Streamlined Workflow.
          </li>
        </ul>
      </div>
      <div class="col-sm-1">

      </div>
    </div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Elevate Your Illustrator Workflow with Crafttor's Smart Tools</h3>

        <div class="figma-list-points">
          <ul>
            <li><img src="assets/img/tick-green.svg" alt=""> Upload from Workspace & access from Illustrator plugin.</li>
            <li><img src="assets/img/tick-green.svg" alt=""> Switch between Workspace and Marketplace.</li>
            <li><img src="assets/img/tick-green.svg" alt=""> Select Marketplace illustrations categories.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="designed-area" style="padding: 0px 0px 100px 0px; background: #ffffff;">
	<div class="container">
		<h2 class="team-head-title">How it works?</h2>
		<div class="row">
			<div class="col-md-4 how-it-works">
        <img class="figma-img-main" src="assets/img/aii.svg">
        <h3>Install Plugin</h3>
        <p>Clicking on Install plugin button will redirect you to Illustrator for Crafttor plugin page. You can install plugin from there and good to start designing.</p>
			</div>
			<div class="col-md-4 how-it-works-img">
        <img class="mix-img" src="assets/img/match.svg">
        <h3>Latest Updates</h3>
        <p>We are always in a sync to have latest plugin available so that our designers can use a more reliable and bugs free plugin for their creatives.</p>
			</div>
			<div class="col-md-4 how-it-works-update">
        <img class="mix-img" src="assets/img/group-88.png">
        <h3>Easy to connect</h3>
        <p>Drop us an email in case of queries or stuck within any use case while using Crafttor' illustrator plugin. Otherwise update your product with better thoughts, designs and comes up with tremendous outcomes.</p>
			</div>
		</div>
	</div>
</section>

<!-- Big CTA Section -->
<section class="cta-section">
  <div class="container">
    <div class="inner-cta">
      <div class="row">
        <div class="col-md-7">
          <h2>Design your impressions with Illustrator plugin to reflect the majesty of your work to the people around you.</h2>
        </div>
        <div class="col-md-5">
          <div class="btn-wrap">
            <a href="https://exchange.adobe.com/apps/cc/109306/crafttor-for-illustrator" target="_blank" class="button gray-btn">
              <img style="width:26px; margin-right: 15px; " class="btnimg" src="assets/img/aii.svg" alt="" />
              Install Plugin</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="figma-solving-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <p>Improved version updates are always in sync, and designers' suggestions are always welcome at <a href="mailto:info@crafttor.com"><b> info@crafttor.com </b></a> </p>
      </div>
    </div>
  </div>
</section>

<!-- FAQs Section start-->
<section class="faq-section-other-pages">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="inner-content">
          <h6>FAQs</h6>
          <hr>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-section design-faq">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is Illustrator Plugin?</h2>
          <p>Illustrator Plugin is a vector graphic tool and can be use worldwide by designers of all types who wants to create illustrations, vector graphics and other use like create illustrations, graphics for web, video etc.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How to use Illustrator plugins?</h2>
          <p>Illustrator is available on Crafttor section of Adobe. Your can simply install the plugin and use it. It's a simple tool and you have to just follow whatever is showing on the plugin. Product(marketplace, workspace) you have to select and good to go. </p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I learn Illustrator plugin features?</h2>
          <p>Illustrator plugin is a great tool for designers. One can simply install the plugin and check for the options provided for different products dropdown(marketplace & workspace) and based on products plugin will show illustrations uploaded by Crafttor(marketplace) and other images uploaded by users(workspace) and use as per the need. More you learn when use the plugin.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question faq-bottom">
        <div class="inner-content">
          <h2>What exactly are vector graphics?</h2>
          <p>Vector graphics are commony used by designers for creating illustrations and other images and are resolution independent. Resolution independent means when a user change the size of the image/illustration it will not effect the quality of image. The quality will remain same even if it resized number of times.</p>
          <hr>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- FAQs Section END -->
