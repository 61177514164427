<section class="icon-d-section">
   <div class="container">
      <div class="row">
         <div class="col-sm-6">
            <div class="inner-content">
               <h1>{{iconTitle}}</h1>
               <p>{{iconDesc}}</p>
               <div class="btn-wrapper">
                 <a href="javascript:;" routerLink="/member" class="button yellow-btn">Premium Membership</a>
                 <a href="javascript:;" class="button gray-btn" (click)="searchIconsRedirect()">Search Icons</a>
               </div>
            </div>
         </div>
         <div class="col-sm-6">
            <div class="img-box">
               <img src="{{iconImage}}" alt="download svg icon">
            </div>
         </div>
      </div>
   </div>
</section>

<section class="three-d-preview text-start">
   <div class="container">
      <h3 class="teamHead-title">Icons</h3>
   </div>
</section>

<section class="icons-section">
	<div class="container">
		<ul class="load-vector" infiniteScroll
		[infiniteScrollDistance]="1"
		[infiniteScrollUpDistance]="2"
		[infiniteScrollThrottle]="50"
		(scrolled)="onScrollDown()">
			<li *ngFor="let illus of iconsList">
				<div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
					<img [src]="illus.image_svg" alt="high quality free svg icons">

					<div class="hover-style">
						<div class="title">{{ illus.title }}</div>
					</div>
				</div>
			</li>
		</ul>
		<div *ngIf="iconsList && iconsList?.length == 0" style="text-align: center; margin-top:50px;">
			<h3>No results found</h3>
			<br />
			<br />
		</div>
		<h3  *ngIf="iconsSimilar?.length > 0" style="font-weight: bold; margin-top: 60px;">Other Icons</h3>
		<ul *ngIf="iconsSimilar?.length > 0"  class="load-vector" style="margin-top: 0px;">
			<li *ngFor="let illus of iconsSimilar">
				<div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
					<img [src]="illus.image_svg" alt="best svg icons">
					<div class="hover-style">
						<!-- <div class="title">{{ illus.title }}</div> -->
					</div>
				</div>
			</li>
		</ul>
		<img src="assets/img/loader.gif" *ngIf="paginationLoading" id="loadMorevector" href="javascript:;" style="display: block;margin-left: auto;margin-right: auto; margin-top:40px; width: 70px; height:60px;">
	</div>
</section>

<!-- Cages category section -->
<section class="icon-vectorimg-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Other Categories</h2>
			</div>
		</div>
		<div class="row" style="display: block;">
			<ul class="load-list">
				<li *ngFor="let icons of iconsCategory">
					<div class="icon-vector-box" routerLink="/icon" [queryParams]="{category: icons.id}" (click)="selectIconsValue(icons)">
						<img [src]="icons.image" alt="line icons">
					</div>

					<div class="category-title">
						<h3>{{ icons.name }}</h3>
					</div>
				</li>
			</ul>
		</div>
	</div>
</section>

<ng-template #downloadModal let-downloadModal>
	<div class="download-assests">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<button type="button" class="close" (click)="downloadModal.close()">
					<img src="assets/img/cross.svg" alt="download free icons">
				</button>
				<div class="modal-body">
					<div class="thumb">
						<img style="width:100px; height:100px;" [src]="currentIllus.image_svg" alt="download svg icons">
					</div>
					<div class="content-right">
						<h3>{{currentIllus.title || ''}}</h3>
						<!-- <div class="style-txt"><strong>Style: </strong>{{currentIllus.category || ''}}</div> -->
						<div class="options">
							<!-- <div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadPngImage()">Download PNG</a>
							</div> -->
							<div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadSvgImage()">Download SVG</a>
							</div>
						</div>
						<div class="bottom-txt">
							<span>Attribution is required.</span>
							<strong>Crafttor License</strong>
							<p>Free for personal and commercial purpose with attribution. <a href="javascript:;" (click)="downloadModal.close(); router.navigate(['/license'])" class="link">More info</a></p>
							<strong>For Exclusive Content & Unlimited Downloads</strong>
							<div class="btn-wrapper"><a class="button yellow-btn" data-dismiss="modal" (click)="downloadModal.close(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
							<span>No attribution required.</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</ng-template>

<ng-template #limitModal let-limitModal>
	<div class="modal-dialog modal-dialog-centered  premium-pop" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="limitModal.close()">
				<img src="assets/img/cross.svg" alt="download funny icons">
			</button>
			<div class="modal-body">
				<h3>Sorry!</h3>
				<h4>You exceed your daily limit</h4>
			<a href="javascript:;" (click)="limitModal.close(); closeDownloadModal();" [routerLink]="['/license', {active: 'readrules'}]" class="simple-link">Read the Rules</a>
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="limitModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #loginModal let-modal>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="openClose()" data-dismiss="modal" aria-label="Close">
				<img src="assets/img/cross.svg" alt="download free icons">
			</button>
			<div class="modal-body">
				<h3>Login</h3>
				<div class="button-wrapper">
					<a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()"><i class="fa fa-google-plus"></i> Continue with Google</a>
					<a href="javascript:;" class="button fb-btn" (click)="signInWithFB()"><i class="fa fa-facebook-f"></i>Continue with Facebook</a>
					<p>By Login, I agree the <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
							[routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
				</div>
			</div>

		</div>
	</div>
</ng-template>
